/**定年分析 */
import request from '@/utils/request'

export function getPageData(data) {
  return request({
    url: '/D_ICPMS/getPageData',
    method: 'post',
    data
  })
}

export default {
  getPageData
}
