<template>
  <div class="h-box-shadow">
    <el-table
      v-loading="table.loading"
      :data="table.data"
      max-height="500px"
      height="400px"
      size="medium"
      stripe
      tooltip-effect="light">
      <el-table-column
        prop="Sample_ID"
        min-width="180"
        label="Sample ID"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Sample_Name"
        min-width="180"
        label="Sample Name"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Sample_fractions"
        min-width="180"
        label="Sample fractions"
        show-overflow-tooltip>
      </el-table-column>

      <el-table-column label="U-Th-Pb dating by SIMS or ICPMS" header-align="center">
        <el-table-column
          prop="U_Th_Pb_U"
          min-width="180"
          label="U"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="U_Th_Pb_Th"
          min-width="180"
          label="Th"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="U_Th_Pb_Pb"
          min-width="180"
          label="Pb"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="U_Th_Pb_Th_U"
          min-width="180"
          label="Th/U"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="U_Th_Pb_207Pb_235U_measured"
          min-width="180"
          label="207Pb/235U"
          show-overflow-tooltip>
          <template slot="header"><sup>207</sup>Pb<sup>235</sup>U</template>
        </el-table-column>
        <el-table-column
          prop="U_Th_Pb_1SE"
          min-width="180"
          label="1SE%"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="U_Th_Pb_206Pb_238Umeasured"
          min-width="180"
          label="206Pb/238U"
          show-overflow-tooltip>
          <template slot="header"><sup>206</sup>Pb<sup>238</sup>U</template>
        </el-table-column>
        <el-table-column
          prop="U_Th_Pb_206Pb_238Umeasured_1SE"
          min-width="180"
          label="1SE%"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="rho"
          min-width="180"
          label="rho (correlation coefficient)"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="D_238U_206Pb_measured"
          min-width="180"
          label="238U/206Pb"
          show-overflow-tooltip>
          <template slot="header"><sup>238</sup>U<sup>206</sup>Pb</template>
        </el-table-column>
        <el-table-column
          prop="D_238U_206Pb_measured_1SE"
          min-width="180"
          label="1SE%"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="D_207Pb_206Pb_measured"
          min-width="180"
          label="207Pb/206Pb"
          show-overflow-tooltip>
          <template slot="header"><sup>207</sup>Pb<sup>206</sup>Pb</template>
        </el-table-column>
        <el-table-column
          prop="D_207Pb_206Pb_measured_1SE"
          min-width="180"
          label="1SE%"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="D_206Pb_204Pb_measured"
          min-width="180"
          label="(206Pb/204Pb)measured"
          show-overflow-tooltip>
          <template slot="header">(<sup>206</sup>Pb<sup>204</sup>Pb)measured</template>
        </el-table-column>
        <el-table-column
          prop="D_206Pb_204Pb_measured_1SE"
          min-width="180"
          label="1SE%"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="Common_Pb_correction"
          min-width="180"
          label="Common Pb correction"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="D_207Pb_206Pb_age"
          min-width="180"
          label="207Pb/206Pb age"
          show-overflow-tooltip>
          <template slot="header"><sup>207</sup>Pb/<sup>206</sup>Pb age</template>
        </el-table-column>
        <el-table-column
          prop="D_207Pb_206Pb_age_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="D_207Pb_235U_age"
          min-width="180"
          label="207Pb/235U age"
          show-overflow-tooltip>
          <template slot="header"><sup>207</sup>Pb/<sup>235</sup>U age</template>
        </el-table-column>
        <el-table-column
          prop="D_207Pb_235U_age_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="D_206Pb_238U_age"
          min-width="180"
          label="206Pb/238U age"
          show-overflow-tooltip>
          <template slot="header"><sup>206</sup>Pb/<sup>238</sup>U age</template>
        </el-table-column>
        <el-table-column
          prop="D_206Pb_238U_age_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="D_206Pb_238U_age"
          min-width="180"
          label="206Pb/238U age"
          show-overflow-tooltip>
          <template slot="header"><sup>206</sup>Pb/<sup>238</sup>U age</template>
        </el-table-column>
        <el-table-column
          prop="D_206Pb_238U_age_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="f206"
          min-width="180"
          label="f206"
          show-overflow-tooltip>
          <template slot="header">f<sub>206</sub></template>
        </el-table-column>

        <el-table-column
          prop="D_208Pb_232Th"
          min-width="180"
          label="(208Pb/232Th)measured"
          show-overflow-tooltip>
          <template slot="header">(<sup>208</sup>Pb/<sup>232</sup>Th)measured</template>
        </el-table-column>
        <el-table-column
          prop="D_208Pb_232Th_1SE"
          min-width="180"
          label="1SE%"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="D_208Pb_232Th_age"
          min-width="180"
          label="208Pb/232Th age"
          show-overflow-tooltip>
          <template slot="header"><sup>208</sup>Pb/<sup>232</sup>Th age</template>
        </el-table-column>
        <el-table-column
          prop="D_208Pb_232Th_age_1SE"
          min-width="180"
          label="1SE%"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="f208"
          min-width="180"
          label="f208"
          show-overflow-tooltip>
          <template slot="header">f<sub>208</sub></template>
        </el-table-column>
      </el-table-column>

      <el-table-column label="U-Pb dating by TIMS" header-align="center">
        <el-table-column
          prop="Sample_Size"
          min-width="180"
          label="Sample Size"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Pretreatment_method"
          min-width="180"
          label="Pretreatment method"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Initial_Pb_composition"
          min-width="180"
          label="Initial Pb composition"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Tracer_name"
          min-width="180"
          label="Tracer name"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="Dates_207Pb_235U"
          min-width="180"
          label="207Pb/235U age"
          show-overflow-tooltip>
          <template slot="header"><sup>207</sup>Pb<sup>235</sup>U age</template>
        </el-table-column>
        <el-table-column
          prop="Dates_207Pb_235U_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="Dates_206Pb_238U"
          min-width="180"
          label="206Pb/238U age"
          show-overflow-tooltip>
          <template slot="header"><sup>206</sup>Pb<sup>238</sup>U age</template>
        </el-table-column>
        <el-table-column
          prop="Dates_206Pb_238U_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="Dates_207Pb_206Pb"
          min-width="180"
          label="207Pb/206Pb age"
          show-overflow-tooltip>
          <template slot="header"><sup>207</sup>Pb<sup>206</sup>Pb age</template>
        </el-table-column>
        <el-table-column
          prop="Dates_207Pb_206Pb_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="Pbc"
          min-width="180"
          label="Pbc"
          show-overflow-tooltip>
          <template slot="header">Pb<sub>c</sub></template>
        </el-table-column>
        <el-table-column
          prop="Pbc"
          min-width="180"
          label="Pbc"
          show-overflow-tooltip>
          <template slot="header">Pb<sub>c</sub></template>
        </el-table-column>
        <el-table-column
          prop="Pb_Pbc"
          min-width="180"
          label="Pb*/Pbc"
          show-overflow-tooltip>
          <template slot="header">Pb*/Pb<sub>c</sub></template>
        </el-table-column>
        <el-table-column
          prop="Composition_U"
          min-width="180"
          label="U"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Isotopic_Ratios_Th_U"
          min-width="180"
          label="Th/U"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="D_206Pb_204Pb"
          min-width="180"
          label="206Pb/204Pb"
          show-overflow-tooltip>
          <template slot="header"><sup>206</sup>Pd<sup>204</sup>Pd</template>
        </el-table-column>
        <el-table-column
          prop="D_208Pb_206Pb"
          min-width="180"
          label="208Pb/206Pb"
          show-overflow-tooltip>
          <template slot="header"><sup>208</sup>Pd<sup>206</sup>Pd</template>
        </el-table-column>

        <el-table-column
          prop="D_207Pb_235U"
          min-width="180"
          label="207Pb/235U"
          show-overflow-tooltip>
          <template slot="header"><sup>207</sup>Pd/<sup>235</sup>U</template>
        </el-table-column>
        <el-table-column
          prop="D_207Pb_235U_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="D_206Pb_238U"
          min-width="180"
          label="206Pb/238U"
          show-overflow-tooltip>
          <template slot="header"><sup>206</sup>Pd/<sup>238</sup>U</template>
        </el-table-column>
        <el-table-column
          prop="D_206Pb_238U_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="correlation_coefficient"
          min-width="180"
          label="correlation coefficient"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="D_207Pb_206Pb"
          min-width="180"
          label="207Pb/206Pb"
          show-overflow-tooltip>
          <template slot="header"><sup>207</sup>Pd/<sup>206</sup>Pb</template>
        </el-table-column>
        <el-table-column
          prop="D_207Pb_206Pb_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="lambda238"
          min-width="180"
          label="λ238"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="lambda235"
          min-width="180"
          label="λ235"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="D_206Pb_204Pb_blank"
          min-width="180"
          label="(206Pb/204Pb)blank"
          show-overflow-tooltip>
          <template slot="header">(<sup>206</sup>Pd/<sup>204</sup>Pb)blank</template>
        </el-table-column>
        <el-table-column
          prop="D_206Pb_204Pb_blank_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="D_207Pb_204Pb_blank"
          min-width="180"
          label="(207Pb/204Pb)blank"
          show-overflow-tooltip>
          <template slot="header">(<sup>207</sup>Pd/<sup>204</sup>Pb)blank</template>
        </el-table-column>
        <el-table-column
          prop="D_207Pb_204Pb_blank_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="D_208Pb_204Pb_blank"
          min-width="180"
          label="(208Pb/204Pb)blank"
          show-overflow-tooltip>
          <template slot="header">(<sup>208</sup>Pd/<sup>204</sup>Pb)blank</template>
        </el-table-column>
        <el-table-column
          prop="D_208Pb_204Pb_blank_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="U_blank_mass"
          min-width="180"
          label="U blank mass"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Th_U_of_melt"
          min-width="180"
          label="Th/U of melt"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="D_231Pa_235U"
          min-width="180"
          label="231Pa/235U"
          show-overflow-tooltip>
          <template slot="header"><sup>231</sup>Pa/<sup>235</sup>U</template>
        </el-table-column>
        <el-table-column
          prop="D_sample"
          min-width="180"
          label="sample"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="blank"
          min-width="180"
          label="blank"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="D_18O_16O"
          min-width="180"
          label="18O/16O"
          show-overflow-tooltip>
          <template slot="header"><sup>18</sup>O/<sup>16</sup>O</template>
        </el-table-column>

      </el-table-column>

      <el-table-column label="K-Ar dating" header-align="center">
        <el-table-column
          prop="K"
          min-width="180"
          label="K"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="D_1SD"
          min-width="180"
          label="1SD"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="D_40Ar_38Ar_measured"
          min-width="180"
          label="(40Ar/38Ar)measured"
          show-overflow-tooltip>
          <template slot="header">(<sup>40</sup>Ar<sup>38</sup>Ar)measured</template>
        </el-table-column>
        <el-table-column
          prop="D_40Ar_38Ar_measured_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="D_38Ar_36Ar_measured"
          min-width="180"
          label="(38Ar/36Ar)measured"
          show-overflow-tooltip>
          <template slot="header">(<sup>38</sup>Ar<sup>36</sup>Ar)measured</template>
        </el-table-column>
        <el-table-column
          prop="D_38Ar_36Ar_measured_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="D_40Ar_radiogenic"
          min-width="180"
          label="40Ar*"
          show-overflow-tooltip>
          <template slot="header"><sup>40</sup>Ar*</template>
        </el-table-column>
        <el-table-column
          prop="D_40K"
          min-width="180"
          label="40K"
          show-overflow-tooltip>
          <template slot="header"><sup>40</sup>K</template>
        </el-table-column>

        <el-table-column
          prop="D_40Ar_40Art"
          min-width="180"
          label="40Ar*/40Art"
          show-overflow-tooltip>
          <template slot="header"><sup>40</sup>Ar*/<sup>40</sup>Art</template>
        </el-table-column>
        <el-table-column
          prop="D_40Ar_40Art_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="D_40Ar_40K"
          min-width="180"
          label="40Ar*/40K"
          show-overflow-tooltip>
          <template slot="header"><sup>40</sup>Ar*/<sup>40</sup>K</template>
        </el-table-column>

        <el-table-column
          prop="K_Ar_age"
          min-width="180"
          label="K-Ar age"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="K_Ar_age_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>
      </el-table-column>

      <el-table-column label="Ar-Ar dating" header-align="center">
        <el-table-column
          prop="Temperature"
          min-width="180"
          label="Temperature"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Laser_power"
          min-width="180"
          label="Laser power"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="D_40Ar_39Ar_measured"
          min-width="180"
          label="(40Ar/39Ar)measured"
          show-overflow-tooltip>
          <template slot="header">(<sup>40</sup>Ar/<sup>39</sup>Ar)measured</template>
        </el-table-column>
        <el-table-column
          prop="D_40Ar_39Ar_measured_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="D_36Ar_39Ar"
          min-width="180"
          label="36Ar/39Ar"
          show-overflow-tooltip>
          <template slot="header"><sup>36</sup>Ar/<sup>39</sup>Ar</template>
        </el-table-column>
        <el-table-column
          prop="D_36Ar_39Ar_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="D_37Ar_39Ar"
          min-width="180"
          label="37Ar/39Ar"
          show-overflow-tooltip>
          <template slot="header"><sup>37</sup>Ar/<sup>39</sup>Ar</template>
        </el-table-column>
        <el-table-column
          prop="D_37Ar_39Ar_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="D_40Ar_39ArK"
          min-width="180"
          label="40Ar*/39ArK"
          show-overflow-tooltip>
          <template slot="header"><sup>40</sup>Ar*/<sup>39</sup>ArK</template>
        </el-table-column>
        <el-table-column
          prop="D_40Ar_39ArK_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="Ar_Ar_40Ar_radiogenic"
          min-width="180"
          label="40Ar*"
          show-overflow-tooltip>
          <template slot="header"><sup>40</sup>Ar*</template>
        </el-table-column>
        <el-table-column
          prop="D_39Ar"
          min-width="180"
          label="39Ar"
          show-overflow-tooltip>
          <template slot="header"><sup>39</sup>Ar</template>
        </el-table-column>
        <el-table-column
          prop="D_40Ar"
          min-width="180"
          label="40Ar"
          show-overflow-tooltip>
          <template slot="header"><sup>40</sup>Ar</template>
        </el-table-column>

        <el-table-column
          prop="K_Ca"
          min-width="180"
          label="K/Ca"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="K_Ca_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="Ar_Ar_age"
          min-width="180"
          label="Ar-Ar age"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Ar_Ar_age_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="J_value"
          min-width="180"
          label="J value"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="J_value_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="Standard_name"
          min-width="180"
          label="Standard name"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="Standard_age"
          min-width="180"
          label="Standard age"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="Decay_constant"
          min-width="180"
          label="40K decay constant"
          show-overflow-tooltip>
          <template slot="header"><sup>40</sup>K decay constant</template>
        </el-table-column>

      </el-table-column>

      <el-table-column label="U-Th/He dating" header-align="center">

        <el-table-column
          prop="D_238U"
          min-width="180"
          label="238U"
          show-overflow-tooltip>
          <template slot="header"><sup>238</sup>U</template>
        </el-table-column>
        <el-table-column
          prop="D_238U_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="D_232Th"
          min-width="180"
          label="232Th"
          show-overflow-tooltip>
          <template slot="header"><sup>232</sup>Th</template>
        </el-table-column>
        <el-table-column
          prop="D_232Th_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="D_4He"
          min-width="180"
          label="4He"
          show-overflow-tooltip>
          <template slot="header"><sup>4</sup>He</template>
        </el-table-column>
        <el-table-column
          prop="D_4He_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="Raw_age"
          min-width="180"
          label="Raw age"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Raw_age_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="FT"
          min-width="180"
          label="FT (alpha ejection correction)"
          show-overflow-tooltip>
          <template slot="header">F<sub>T</sub> (alpha ejection correction)</template>
        </el-table-column>
        <el-table-column
          prop="FT_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="Th_U"
          min-width="180"
          label="Th/U"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Mass"
          min-width="180"
          label="Mass"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Radius"
          min-width="180"
          label="Radius"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="D_Length"
          min-width="180"
          label="Length"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Width"
          min-width="180"
          label="Width"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="U"
          min-width="180"
          label="U"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="U"
          min-width="180"
          label="U"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Th"
          min-width="180"
          label="Th"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="eU"
          min-width="180"
          label="eU (concentration of effective Uranium)"
          show-overflow-tooltip>
        </el-table-column>

      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import dICPMSApi from '@/api/dICPMS'
export default {
  name: 'Search_Detail_dICPMS',
  data() {
    return {
      sampleId: this.$route.query.sampleId,
      table: {
        loading: false,
        data: []
      },
      page: {
        index: 1,
        size: 100,
        total: 0
      }
    }
  },
  methods: {
    getData() {
      if (!this.sampleId) {return}
      this.table.loading = true
      dICPMSApi.getPageData({
        page: this.page.index,
        rows: this.page.size,
        wheres: `[
          {"name":"Sample_ID","value":"${this.sampleId}","displayType":"number"}
        ]`.replace(/\s+/g,"")
      }).then(res => {
        this.table.loading = false
        this.table.data = res.rows
        this.page.total = res.total
      }).catch(err => {
        this.talbe.loading = false
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
  /deep/ .el-pagination {
    margin: 12px 0;
  }
</style>
